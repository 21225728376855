body {
  padding: 0;
  margin: 0;
  scroll-behavior: none;
}
.close-icon :hover {
  color: rgb(175, 13, 13);
  cursor: pointer;
}
.hover-icon :hover {
  cursor: pointer;
}
.float_right {
  float: right;
}
.no-data-text {
  text-align: right;
}
.text_white {
  color: #ffffff;
}
.file_upload {
  width: 100px;
  height: 100px;
}
.semi-dark {
  background: #ddd;
}
@media (max-width: 767px) {
  .hide-sm {
    display: none;
  }
  .float_right {
    float: none;
  }
}

@media (max-width: 767px) {
  .site-layout {
    display: block;
  }
}

.site-layout {
  background: #fff;
  padding: 24px 0;
  min-height: 620px;
}

.site-layout-background {
  background: #fff;
}

.wrap-long-text {
  overflow-y: auto;
  max-height: 610px;
}

.auth-container {
  padding: 0 50px;
  height: 100vh;
  display: grid;
  background-color: #011834;
  place-items: center;
}
.auth-card {
  height: fit-content;
  width: 300px;
  border-radius: 5px;
}
.gwiza--header {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
}
.gwiza--menu {
  position: relative !important;
}
.gwiza--user {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
}

.gwiza-mr-3 {
  margin-right: 3px;
}
.gwiza-mt-3 {
  margin-top: 3px;
}
.gwiza-mt-2 {
  margin-top: 2px;
}
.pointer {
  cursor: pointer;
}
.col-title {
  display: flex;
  justify-content: space-between;
}
